Copy<template>
  <div class="app">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <h1><router-link to="/blog" class="blog-title">Premium Domain Valuation Reports</router-link></h1>
          <p class="blog-subtitle">Insights on premium domain names</p>
        </div>
<!-- <nav class="blog-nav">
  <ul class="nav-links">
    <li><router-link to="/" class="nav-link">Home</router-link></li>
    <li><router-link to="/blog" class="nav-link">Blog</router-link></li>
    <li><router-link to="/domains" class="nav-link">Featured Domains</router-link></li>
  </ul>
</nav> -->
      </div>
    </header>

    <main class="main-content">
      <div class="container">
        <!-- This is where child components will be rendered -->
        <slot></slot>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: 'BlogLayout',
  components: {
    Footer
  }
});
</script>