<template>
  <div class="home-page">
    <div class="content">
      <header>
        <h1>Welcome to Baj.ai</h1>
        <p class="intro-subheading">Transforming high-value domains into successful enterprises</p>
        <p class="intro-text">
          Exceptional domain names are more than just web addresses—they're the cornerstones of memorable brands and successful businesses.
          Through strategic curation and collaboration with best-in-class entrepreneurs, we're enabling many next-generation companies to build on the strongest possible domain names.
        </p>
        <p class="intro-text">Build something extraordinary!</p>
      </header>

     
      <section class="search-section">
        <h2 class="headline">Search and Browse Domains</h2>
        <SearchBar @search-results="handleSearchResults" /> 
      </section> 

      <!-- Available Domains -->
      <section>
        <h2>Available Domains</h2>
        <ul class="domain-list">
          <li
          v-for="domain in searchResults.length > 0 ? searchResults : sortedDomains"
          :key="domain.name"
          class="domain-item"
          >
            <router-link
              :to="`/domains/${domain.name}.html`"
              class="domain-link"
            >
              {{ domain.name }}
            </router-link>
          </li>
        </ul>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import DomainList from "@/components/DomainList.vue";
import SearchBar from "@/components/SearchBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomePage",
  components: {
    DomainList,
    SearchBar,
    Footer,
  },
  setup() {
    const domains = ref([]);
    const searchResults = ref([]);

    const fetchDomains = async () => {
      try {
        console.log("[HomePage] Fetching test_domains.json dynamically in client");
        const response = await fetch("/test_domains.json");
        if (response.ok) {
          const data = await response.json();
          console.log("[HomePage] Fetched Domains:", data);
          domains.value = data;
        } else {
          console.error(
            "[HomePage] Failed to fetch test_domains.json:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("[HomePage] Error fetching test_domains.json:", error);
      }
    };

    const sortedDomains = computed(() => {
      return domains.value.sort((a, b) => a.name.localeCompare(b.name));
    });

    const handleSearchResults = (results) => {
      console.log("[HomePage] Search results:", results.length, "domains found");
      searchResults.value = results;
      // You can do something with the results here if needed
      // For example: filter the visible domains list or navigate to a search results page
    };

    onMounted(() => {
      fetchDomains();
    });

    return {
      domains,
      sortedDomains,
      searchResults,
      handleSearchResults,
    };
  },
};
</script>
<!-- No scoped styles - using global CSS instead -->