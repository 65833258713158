<template>
  <div class="domain-page">
    <div v-if="isLoading">
      <LoadingMessages />
    </div>
    <div v-else-if="localDomain">
      <div class="domain-container">
        <div class="domain-header">
          <div class="text-container">
            <h1 class="domain-title" :class="{'long-domain': localDomain.name.length > 10}">
              {{ localDomain.name.toUpperCase() }}
            </h1>
            <h2 class="intro-subheading">
              This premium domain name is available for purchase now. Get it before someone else does. Make it yours today.
            </h2>
          </div>
        </div>
        
        <p class="domain-description">
          <strong>Imagine:</strong> {{ localDomain.description }}
        </p>

        <p class="features-list">
          <strong>Features:</strong> {{ localDomain.features.join(', ') }}
        </p>

        <p class="keywords-list">
          <strong>Keywords:</strong> {{ localDomain.keywords.join(', ') }}
        </p>

        <!-- Display Price -->
        <p class="domain-price">
          <strong>Price:</strong> {{ localDomain.price ? `$${Math.floor(localDomain.price)}` : "Contact for Price" }}
        </p>

        <div class="button-container">
          <!-- Show Escrow "Buy Now" button if available -->
          <a v-if="localDomain.actions.buy_now && localDomain.actions.buy_now.includes('escrow')"
            :href="localDomain.actions.buy_now"
            target="_blank"
            class="escrow-button">
            Buy Now (via Escrow.com)
          </a>

          <!-- Show "Make an Offer" button if available -->
          <a v-if="localDomain.actions.make_offer"
            :href="localDomain.actions.make_offer"
            target="_blank"
            class="escrow-button">
            Make an Offer (via Escrow.com)
          </a>

          <!-- Fallback to Afternic buy link if no Escrow Buy Now link -->
          <a v-else-if="localDomain.actions.buy_now"
            :href="localDomain.actions.buy_now"
            target="_blank"
            class="buy-now-button">
            Buy Now (Broker)
          </a>

          <!-- Ensure Contact Domain Owner button appears if there's an email -->
          <a v-if="localDomain.actions.contact && localDomain.actions.contact !== ''"
            :href="localDomain.actions.contact"
            class="contact-button">
            Contact Domain Owner
          </a>
          
          <!-- Value Report button linking to blog post  - commented out for now/was not working right
          <router-link 
            :to="`/blog/${encodeURIComponent(localDomain.name)}`"
            class="value-report-button">
            Discussion
          </router-link> -->
          
          <!-- Premium Report button shown only when a premium report exists -->
          <a v-if="hasPremiumReport"
            :href="premiumReportPath"
            target="_blank"
            class="premium-report-button">
            Premium Report Available
          </a>
        </div>
      </div>
    </div>
    <div v-else class="domain-not-found">
      <h1>Domain Not Found</h1>
      <p>The requested domain could not be found in our records.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LoadingMessages from "@/components/LoadingMessages.vue";
import { useHead } from '@unhead/vue';
import { defineComponent, ref, onMounted, watchEffect } from "vue";

export default defineComponent({
  name: 'DomainPage',
  components: {
    Footer,
    LoadingMessages
  },
  props: {
    domainName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const localDomain = ref(null);
    const isLoading = ref(true);
    const loadStartTime = ref(null);
    const domainList = ref([]);
    const hasPremiumReport = ref(false);
    const premiumReportPath = ref('');
    const premiumReports = ref({});

    // Force global styles to be applied even in development preview
    console.log("🔍 Ensuring global styles are applied to domain page");

    const completeLoading = (domain) => {
      const now = Date.now();
      const loadingDuration = now - loadStartTime.value;
      const minimumDuration = 750;

      console.log("⏳ Loading duration:", loadingDuration);

      if (loadingDuration >= minimumDuration) {
        console.log("✅ Minimum loading duration met. Finishing loading...");
        finishLoading(domain);
      } else {
        const remainingTime = minimumDuration - loadingDuration;
        console.log("🕒 Waiting for remaining time:", remainingTime);
        setTimeout(() => {
          finishLoading(domain);
        }, remainingTime);
      }
    };

    const finishLoading = (domain) => {
      console.log("🏁 Finishing loading with domain data:", domain);
      localDomain.value = domain;
      isLoading.value = false;
      
      // Check for premium report after domain is loaded
      if (domain) {
        checkForPremiumReport(domain.name);
      }
    };

    const checkForPremiumReport = (domainName) => {
      fetch('/premium-reports.json')
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log("📄 Premium reports data fetched:", data);
          premiumReports.value = data;
          
          // Check if this domain has a premium report
          // We need to check various formats of the domain name since filenames might vary
          const domainKey = domainName.toLowerCase();
          
          if (premiumReports.value[domainKey]) {
            hasPremiumReport.value = true;
            premiumReportPath.value = premiumReports.value[domainKey].reportPath;
            console.log(`🌟 Found premium report for ${domainKey}:`, premiumReportPath.value);
          } else {
            console.log(`ℹ️ No premium report found for ${domainKey}`);
            hasPremiumReport.value = false;
          }
        })
        .catch(error => {
          console.error('Failed to fetch premium reports:', error);
          hasPremiumReport.value = false;
        });
    };

    const loadDomainData = () => {
      console.log("🔄 Loading domain data...");
      loadStartTime.value = Date.now();
      isLoading.value = true;

      fetch('/test_domains.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("📄 Domain data fetched:", data);
          domainList.value = data;
          const resolvedDomain = data.find(
            (domain) => domain.name.trim().toLowerCase() === props.domainName.toLowerCase()
          );

          if (!resolvedDomain) {
            console.warn(`⚠️ No matching domain found for: ${props.domainName}`);
          } else {
            console.log(`🎯 Matched domain: ${resolvedDomain.name}`, resolvedDomain);
          }

          completeLoading(resolvedDomain);
        })
        .catch((error) => {
          console.error('[ERROR] Failed to fetch testDomains.json:', error);
          completeLoading(null);
        });
    };

    watchEffect(() => {
      if (!localDomain.value) return;
      console.log("🚀 Running updateMetadata...");

       // Commented out to let router handle metadata
  /*

      const actions = ["Buy", "Own", "Acquire"];
      const randomAction = actions[Math.floor(Math.random() * actions.length)];
      const description = localDomain.value.description || '';
      const firstSentence = description.split('. ')[0] + '.';
      const domainURL = `https://${localDomain.value.name}`;

      useHead({
        title: `${randomAction} ${localDomain.value.name} before someone else does. ${firstSentence}`,
        meta: [
          { name: "description", content: `${localDomain.value.name} is a premium asset for your business. ${randomAction} ${localDomain.value.name}. ${description}` },
          { property: "og:title", content: `${randomAction} ${localDomain.value.name} before someone else does.` },
          { property: "og:description", content: description },
          { property: "og:type", content: "website" },
          { property: "og:url", content: domainURL },
          { property: "og:image", content: `${domainURL}/images/default.png` },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:title", content: `${randomAction} ${localDomain.value.name} before someone else does.` },
          { name: "twitter:description", content: description },
          { name: "twitter:image", content: `${domainURL}/images/default.png` }
        ],
        link: [
          // Force include the global CSS
          { rel: 'stylesheet', href: '/assets/styles/global.css' }
        ],
        script: [
          {
            src: 'https://www.googletagmanager.com/gtag/js?id=G-KC5YVMXGKN',
            async: true
          },
          {
            children: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-KC5YVMXGKN');
            `
          }
        ]
      });

       */

      console.log("✅ Metadata updated successfully.");
    });

    onMounted(() => {
      console.log("🎉 DomainPage component created.");
      loadDomainData();
    });

    return { 
      localDomain, 
      isLoading, 
      domainList, 
      hasPremiumReport, 
      premiumReportPath 
    };
  }
});
</script>
<!-- Removed scoped styles completely to use global styles -->