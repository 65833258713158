import HomePage from "@/components/HomePage.vue";
import DomainPage from "@/components/DomainPage.vue";
import AboutPage from "@/components/About.vue";
import FAQPage from "@/components/FAQ.vue";
import InvolvedPage from "@/components/Involved.vue";
import NotFoundPage from "@/components/404.vue";
import BlogIndex from "@/components/blog/BlogIndex.vue";
import DomainBlogPost from "@/components/blog/DomainBlogPost.vue";
import testDomains from "@/test_domains.json";

// Add tracking function
const trackPageView = (to) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', 'G-KC5YVMXGKN', {
      page_path: to.fullPath
    });
  }
};

const staticRoutes = [
  { 
    path: "/", 
    name: "Home", 
    component: HomePage,
    meta: {
      title: 'Baj.ai - Premium Domains for Sale',
      description: 'Discover premium domain names for sale. Browse our collection of high-quality domains for your business or project.',
      contentType: 'website',
      canonical: 'https://baj.ai/'
    }
  },
  { 
    path: "/about", 
    name: "About", 
    component: AboutPage,
    meta: {
      title: 'About Baj.ai - Premium Domain Marketplace',
      description: 'Learn more about Baj.ai and our mission to provide high-quality domain names for businesses and projects.',
      contentType: 'website',
      canonical: 'https://baj.ai/about'
    }
  },
  { 
    path: "/contact", 
    name: "Contact", 
    component: InvolvedPage,
    meta: {
      title: 'Contact Baj.ai - Get in Touch About Premium Domains',
      description: 'Contact our team to inquire about our premium domain names or request more information about our services.',
      contentType: 'website',
      canonical: 'https://baj.ai/contact'
    }
  },
  { 
    path: "/faq", 
    name: "FAQ", 
    component: FAQPage,
    meta: {
      title: 'Frequently Asked Questions - Baj.ai Domain Marketplace',
      description: 'Find answers to common questions about our domain marketplace, purchasing process, and services.',
      contentType: 'website',
      canonical: 'https://baj.ai/faq'
    }
  },
  { 
    path: "/blog", 
    name: "BlogIndex", 
    component: BlogIndex,
    meta: {
      title: 'Domain Analysis Blog - Baj.ai',
      description: 'Explore our blog featuring detailed domain analyses, market insights, and industry trends for premium domain names.',
      contentType: 'blog',
      isBlogPost: true,
      canonical: 'https://baj.ai/blog'
    }
  },
  { 
    path: "/featured-domains", 
    name: "FeaturedDomains", 
    component: () => import('../components/FeaturedDomains.vue'),
    meta: {
      title: 'Featured Domain Reports | Premium .AI Domains',
      description: 'Explore our collection of featured .AI domain reports with comprehensive analysis and valuation information for premium artificial intelligence domain names.',
      contentType: 'website',
      canonical: 'https://baj.ai/featured-domains'
    }
  }
];

const validDomains = testDomains.filter((domain, index) => {
  if (!domain.name || typeof domain.name !== "string") {
    console.error(`Invalid domain entry at index ${index}:`, domain);
    return false;
  }
  return true;
});

// Create two routes for each domain - one with .html and one without
// Both routes should use the same handler for consistency
const domainRoutes = validDomains.flatMap((domain) => [
  {
    path: `/domains/${encodeURIComponent(domain.name)}`,
    name: `domain-${domain.name}`,
    component: DomainPage,
    props: route => ({ 
      domainName: decodeURIComponent(domain.name) 
    }),
    meta: {
      title: `Buy ${domain.name} - Premium Domain For Sale | Baj.ai`,
      description: `Purchase the premium domain ${domain.name} for your business. View features, pricing, and make an offer today.`,
      isBlogPost: false,
      contentType: 'product',
      canonical: `https://baj.ai/domains/${domain.name}`,
      structuredData: {
        '@type': 'Product',
        'name': domain.name,
        'offers': {
          '@type': 'Offer',
          'priceCurrency': 'USD'
        }
      }
    }
  },
  {
    path: `/domains/${encodeURIComponent(domain.name)}.html`,
    name: `domain-${domain.name}-html`,
    component: DomainPage,
    props: route => ({ 
      domainName: decodeURIComponent(domain.name) 
    }),
    meta: {
      title: `Buy ${domain.name} - Premium Domain For Sale | Baj.ai`,
      description: `Purchase the premium domain ${domain.name} for your business. View features, pricing, and make an offer today.`,
      isBlogPost: false,
      contentType: 'product',
      canonical: `https://baj.ai/domains/${domain.name}`,
      structuredData: {
        '@type': 'Product',
        'name': domain.name,
        'offers': {
          '@type': 'Offer',
          'priceCurrency': 'USD'
        }
      }
    }
  }
]);

// Create blog post routes for each domain
// Also provide both with and without .html versions
const blogDomainRoutes = validDomains.flatMap((domain) => [
  {
    path: `/blog/${encodeURIComponent(domain.name)}`,
    name: `blog-${domain.name}`,
    component: DomainBlogPost,
    props: route => ({ 
      domain: decodeURIComponent(domain.name)
    }),
    meta: {
      title: `${domain.name} Domain Analysis: Market Value & Potential Uses | Baj.ai Blog`,
      description: `Expert analysis of the ${domain.name} domain name. Explore use cases, market value assessment, and industry applications in our detailed report.`,
      isBlogPost: true,
      contentType: 'article',
      canonical: `https://baj.ai/blog/${domain.name}`,
      structuredData: {
        '@type': 'Article',
        'headline': `${domain.name} Domain Analysis and Valuation Report`,
        'author': {
          '@type': 'Organization',
          'name': 'Baj.ai'
        },
        'publisher': {
          '@type': 'Organization',
          'name': 'Baj.ai'
        },
        'datePublished': new Date().toISOString().split('T')[0]
      }
    }
  },
  {
    path: `/blog/${encodeURIComponent(domain.name)}.html`,
    name: `blog-${domain.name}-html`,
    component: DomainBlogPost,
    props: route => ({ 
      domain: decodeURIComponent(domain.name)
    }),
    meta: {
      title: `${domain.name} Domain Analysis: Market Value & Potential Uses | Baj.ai Blog`,
      description: `Expert analysis of the ${domain.name} domain name. Explore use cases, market value assessment, and industry applications in our detailed report.`,
      isBlogPost: true,
      contentType: 'article',
      canonical: `https://baj.ai/blog/${domain.name}`,
      structuredData: {
        '@type': 'Article',
        'headline': `${domain.name} Domain Analysis and Valuation Report`,
        'author': {
          '@type': 'Organization',
          'name': 'Baj.ai'
        },
        'publisher': {
          '@type': 'Organization',
          'name': 'Baj.ai'
        },
        'datePublished': new Date().toISOString().split('T')[0]
      }
    }
  }
]);

// Additional blog routes
const additionalBlogRoutes = [
  {
    path: '/blog/keyword/:keyword',
    name: 'KeywordPage',
    component: () => import('@/components/blog/KeywordPage.vue'),
    props: true,
    meta: {
      title: 'Domain Keyword Analysis | Baj.ai Blog',
      description: 'Explore domain names related to specific keywords and their market potential for your business or project.',
      isBlogPost: true,
      contentType: 'article',
      canonical: (route) => `https://baj.ai/blog/keyword/${route.params.keyword}`
    }
  }
];

// Add a catch-all route for domains to handle direct access
// Improved to handle both with and without .html extensions
const fallbackRoute = {
  path: '/domains/:pathMatch(.*)*',
  name: 'domain-fallback',
  component: DomainPage,
  props: route => {
    // Clean up the pathMatch parameter to handle both cases
    const pathMatch = Array.isArray(route.params.pathMatch) 
      ? route.params.pathMatch.join('/') 
      : route.params.pathMatch;
    
    // Remove .html extension if present
    const domainName = decodeURIComponent(pathMatch.replace(/\.html$/, ''));
    console.log(`Resolving domain fallback for: ${domainName}`);
    return { domainName };
  },
  meta: {
    title: 'Premium Domain for Sale | Baj.ai',
    description: 'View details and pricing for this premium domain name available for purchase on Baj.ai.',
    isBlogPost: false,
    contentType: 'product',
    canonical: (route) => `https://baj.ai/domains/${route.params.pathMatch}`
  }
};

// Add the 404 catch-all route as the final fallback
const notFoundRoute = {
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  component: NotFoundPage,
  meta: {
    title: 'Page Not Found | Baj.ai',
    description: 'The page you are looking for could not be found. Please check the URL or navigate back to our homepage.',
    contentType: 'website',
    canonical: 'https://baj.ai/404'
  }
};

// Export the routes array
export const routes = [
  ...staticRoutes,
  ...domainRoutes, // Place domain routes first for better matching
  ...blogDomainRoutes,
  ...additionalBlogRoutes,
  fallbackRoute,
  notFoundRoute
];

// Export a function to create the router - this helps with SSR compatibility
export function createAppRouter(history) {
  // Import here to avoid SSR issues
  const { createRouter } = require('vue-router');
  
  const router = createRouter({
    history,
    routes,
    scrollBehavior(to, from, savedPosition) {
      // If they used the back/forward buttons and have a saved position
      if (savedPosition) {
        return savedPosition;
      }
      // Otherwise scroll to top
      return { top: 0 };
    }
  });

  // Add tracking only on client side
  if (typeof window !== 'undefined') {
    router.beforeEach((to, from, next) => {
      // Make sure domain name is clean for domain pages
      if (to.path.includes('/domains/')) {
        console.log(`Processing domain route: ${to.path}`);
      }
      trackPageView(to);
      next();
    });
  }

  return router;
}

export { trackPageView };