import { ViteSSG } from 'vite-ssg';
import App from './App.vue';
import { routes, trackPageView } from './router';
import './assets/styles/global.css';
import { createHead } from '@unhead/vue';

export const createApp = ViteSSG(
  App,
  { 
    routes,
    scrollBehavior(to, from, savedPosition) {
      // If they used the back/forward buttons and have a saved position
      if (savedPosition) {
        return savedPosition;
      }
      // Otherwise scroll to top
      return { top: 0 };
    }
  },
  ({ app, router, isClient }) => {
    const head = createHead();
    app.use(head);
    
    if (isClient) {
      router.beforeEach((to, from, next) => {
        if (to.path.startsWith('/domains/')) {
          const domainName = decodeURIComponent(to.path.split('/').pop().replace('.html', ''));
          to.params.domainName = domainName;
        }
        next();
      });

      router.afterEach(trackPageView);
    }
  }
);