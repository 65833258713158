<template>
  <div class="page-container">
    <div class="content">
      <h1 class="title-primary">Page Not Found</h1>
      <p class="text-content">
        We're sorry, but the page you're looking for cannot be found. This might be because:
      </p>
      
      <h2 class="subtitle-primary">What Happened?</h2>
      <p class="text-content">
        The page may have been moved, deleted, or never existed. Don't worry though - 
        you can find your way back to our valuable domain resources and services.
      </p>

      <h2 class="subtitle-primary">What Can You Do?</h2>
      <p class="text-content">
        You can try these options to find what you're looking for:
      </p>
      <ul>
        <li>Return to our <router-link to="/" class="text-link">homepage</router-link></li>
        <li>Browse our <router-link to="/" class="text-link">domain inventory</router-link></li>
        <li>Contact us if you need assistance</li>
      </ul>

      <h2 class="subtitle-primary">Looking for Premium Domains?</h2>
      <p class="text-content">
        While you're here, explore our carefully curated collection of premium domain names. 
        At Baj.ai, we specialize in exceptional digital real estate that can transform your 
        business vision into reality.
      </p>

      <p class="text-content">
        Let us help you find the perfect digital foundation for your next venture.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "NotFound",
  components: {
    Footer,
  },
  // Add meta information for SEO
  metaInfo: {
    title: "404 - Page Not Found | Baj.ai",
    meta: [
      {
        name: "description",
        content: "Sorry, the page you are looking for cannot be found. Explore our premium domain inventory or return to homepage to discover exceptional digital real estate opportunities."
      },
      {
        name: "robots",
        content: "noindex, follow"
      }
    ]
  }
};
</script>
<!-- No styles - using global CSS instead -->