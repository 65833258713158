<template>
  <div class="page-container">
    <div class="content">
      <h1 class="title-primary">About Us</h1>
      <p class="text-content">
        At Baj.ai (pronounced "Badge"), we help transform premium digital real estate into thriving businesses. 
        We are a domain-driven venture studio specializing in identifying, acquiring, selling, and 
        developing exceptional domain names into valuable digital enterprises.
      </p>
      
      <h2 class="subtitle-primary">Our Inventory</h2>
      <p class="text-content">
        Our portfolio includes many carefully curated premium and ultra-premium domain names, 
        each selected for its potential to anchor innovative business ventures. These digital assets serve 
        as the foundation for our business development strategy, allowing us to build compelling brands 
        with inherent market advantage.
      </p>

      <h2 class="subtitle-primary">Sale, Lease, and Purchase</h2>
      <p class="text-content">
        The domains we list are available for sale. In some cases, we will lease, and in some cases, we will purchase (or trade).
      </p>

      <h2 class="subtitle-primary">Development</h2>
      <p class="text-content">
        We are veteran Internet business builders who understand both the technical and 
        commercial aspects of digital venture creation. From concept to execution, we can apply proven methodologies 
        to transform high-value domains into successful enterprises.
      </p>

      <h2 class="subtitle-primary">Our Vision</h2>
      <p class="text-content">
        We believe that exceptional domain names are more than just web addresses—they're the cornerstones 
        of memorable brands and successful businesses. Through strategic development and expert management, 
        we're building the next generation of digital companies on the strongest possible foundations.
      </p>

      <p class="text-content">
        Let's build something extraordinary together.
      </p>
    </div>
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "About", // Changed from "Involved" to "About" to match component purpose
  components: {
    Footer,
  },
};
</script>
<!-- No styles - using global CSS instead -->