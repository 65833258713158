<script>
import domains from "../test_domains.json"; // Adjust path as needed

export default {
  data() {
    return {
      domains: domains, // Preloaded domains from static file
      searchQuery: "",
    };
  },
  computed: {
    filteredDomains() {
      const query = this.searchQuery.trim().toLowerCase();
      
      const filtered = query
        ? this.domains.filter((domain) => {
            // Check domain name
            const nameMatch = domain.name.toLowerCase().includes(query);
            
            // Check description
            const descriptionMatch =
              domain.description &&
              domain.description.toLowerCase().includes(query);
            
            // Check features
            let featuresMatch = false;
            if (domain.features) {
              if (typeof domain.features === 'string') {
                featuresMatch = domain.features.toLowerCase().includes(query);
              } else if (Array.isArray(domain.features)) {
                featuresMatch = domain.features.some(
                  feature => feature.toLowerCase().includes(query)
                );
              }
            }
            
            // Check keywords
            let keywordsMatch = false;
            if (domain.keywords) {
              if (typeof domain.keywords === 'string') {
                keywordsMatch = domain.keywords.toLowerCase().includes(query);
              } else if (Array.isArray(domain.keywords)) {
                keywordsMatch = domain.keywords.some(
                  keyword => keyword.toLowerCase().includes(query)
                );
              }
            }

            return nameMatch || descriptionMatch || featuresMatch || keywordsMatch;
          })
        : this.domains;

      return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    handleSearch() {
      // Search was performed, emit event with results
      this.$emit('search-results', this.filteredDomains);
      
      // You can route to a results page if desired
      // this.$router.push({ path: '/results', query: { q: this.searchQuery } });
    },
  },
};
</script>

<template>
  <div class="domain-list">
    <!-- Search Bar only -->
    <div class="search-container">
      <input
        v-model="searchQuery"
        placeholder="Search domains..."
        type="text"
        class="search-input"
        @input="handleSearch"
      />
      <button @click="handleSearch" class="search-button">Search</button>
    </div>
  </div>
</template>
<!-- No scoped styles - using global CSS instead -->