<template>
  <BlogLayout>
    <div class="domain-page">
      <!-- Back button -->
      <div class="back-link">
        <router-link to="/blog">← Back to Blog</router-link>
      </div>

      <div v-if="isLoading">
        <LoadingMessages />
      </div>

      <div v-else-if="!domainData" class="domain-not-found">
        <h1>Domain Not Found</h1>
        <p>The requested domain blog post could not be found.</p>
        <router-link to="/blog" class="contact-button">Return to Blog</router-link>
      </div>

      <div v-else class="domain-container">
        <!-- Blog post header -->
        <div class="domain-header">
          <!--
          <img 
            :src="selectedImage"
            :alt="'Abstract illustration for ' + domain"
            class="domain-image"
          /> 
          -->
          <div class="text-container">
            <h1 class="domain-title">{{ domain.toUpperCase() }}</h1>
            <h2 class="intro-subheading">Premium Domain Discussion</h2>
          </div>
        </div>

        <!-- Domain report link (new section) -->
        <section v-if="reportExists" class="report-section">
          <h2>Detailed Analysis</h2>
          <p>
            We have prepared a comprehensive report for this domain with in-depth market analysis
            and valuation insights.
          </p>
          <router-link :to="`/blog/reports/${domain}`" class="report-link">
            View Detailed Report
          </router-link>
        </section>

        <!-- Domain value proposition -->
        <section>
          <h2>Value & Potential</h2>
          <p class="domain-description">
            {{ domain.toUpperCase() }} represents a premium domain acquisition opportunity. This domain name combines 
            relevance, memorability, and descriptive power to create an instantly recognizable web address 
            for AI-focused projects.
          </p>
          <p class="domain-description">
            With the artificial intelligence sector growing at an unprecedented rate, securing a domain like 
            {{ domain }} positions your venture at the forefront of innovation. The extension further 
            reinforces the focus and provides immediate context for visitors.
          </p>
          <p v-if="domainData.description" class="domain-description">
            <strong></strong> {{ domainData.description }}
          </p>
        </section>

        <!-- Potential use cases section -->
        <section>
          <h2>Potential Applications</h2>
          <ul class="features-list">
            <li v-for="(useCase, index) in potentialUseCases" :key="index">
              {{ useCase }}
            </li>
          </ul>
        </section>

        <!-- Domain features -->
        <section v-if="domainFeatures.length > 0">
          <h2>Domain Characteristics</h2>
          <ul class="features-list">
            <li v-for="(feature, index) in domainFeatures" :key="index">
              <strong>{{ feature.title }}:</strong> {{ feature.description }}
            </li>
          </ul>
        </section>

        <!-- Display features if available in domain data -->
        <section v-if="domainData.features && domainData.features.length > 0">
          <h2>Suitabilty Analysis</h2>
          <p class="features-list">
            <strong>Ideal for:</strong> {{ domainData.features.join(', ') }}
          </p>
        </section>

        <!-- Display keywords if available -->
        <section v-if="domainData.keywords && domainData.keywords.length > 0">
          <h2>Keywords</h2>
          <div class="keywords-container">
            <router-link
              v-for="keyword in domainData.keywords"
              :key="keyword"
              :to="`/blog/keyword/${encodeURIComponent(keyword.trim())}`"
              class="keyword-tag">
              {{ keyword.trim() }}
            </router-link>
          </div>
        </section>

        <!-- Display Price if available -->
        <p v-if="domainData.price" class="domain-price">
          <strong>Price:</strong> {{ `$${Math.floor(domainData.price)}` }}
        </p>

        <!-- Call to action -->
        <div class="button-container">
          <router-link :to="`/domains/${domain}.html`" class="buy-now-button">
            Next Steps
          </router-link>
        </div>
        
        <!-- Keywords section with the enhanced linking system. Note that when this was commented out, basically the section of other domains by keyword went with it.  That feature had some plusses.-->
      <!--   <KeywordSection 
          :domain="domain" 
          :key="domain"
          :showSampleDomains="true" 
          :maxSampleDomainsPerKeyword="10"
        />
        -->
      </div>
    </div>
  </BlogLayout>
</template>

import { defineComponent, ref, onMounted, onServerPrefetch, watch } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import BlogLayout from './BlogLayout.vue';
import KeywordSection from './KeywordSection.vue';
import LoadingMessages from "@/components/LoadingMessages.vue";

export default defineComponent({
  name: 'DomainBlogPost',
  components: {
    BlogLayout,
    KeywordSection,
    LoadingMessages
  },
  props: {
    domain: {
      type: String,
      required: true
    }
  },
  setup(props) {
    console.log(`[DomainBlogPost] Component initializing for domain: "${props.domain}"`);
    
    const route = useRoute();
    const isLoading = ref(true);
    const domainData = ref(null);
    const selectedImage = ref(null);
    const loadStartTime = ref(Date.now());
    const domainFeatures = ref([]);
    const potentialUseCases = ref([]);
    const publicationDate = ref(new Date().toISOString().split('T')[0]); // Format: YYYY-MM-DD
    const reportExists = ref(false);  // New state to track if a report exists

    // Function to select a random image for the domain
    const selectRandomImage = () => {
      const imageCount = 32;
      // Use domain name as a seed for consistent image selection
      const domainSeed = props.domain.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
      const imageIndex = (domainSeed % imageCount) + 1;
      selectedImage.value = `/images/${imageIndex}.svg`;
      console.log(`[DomainBlogPost] Selected image ${imageIndex} for domain "${props.domain}"`);
    };

    // Function to check if a report exists for this domain
    const checkReportExists = async () => {
      try {
        // For a more reliable check, fetch the premium-reports.json which contains domains with reports
        const reportsListResponse = await fetch('/premium-reports.json');
        if (reportsListResponse.ok) {
          const reportsList = await reportsListResponse.json();
          reportExists.value = reportsList.includes(props.domain);
        } else {
          // Fallback to a HEAD request to check if the domain has a report
          const response = await fetch(`/domain-reports/Analysis_of_${props.domain}_by_Baj.ai_2025-03-14.pdf`, { method: 'HEAD' });
          reportExists.value = response.ok;
        }
        
        console.log(`[DomainBlogPost] Report for "${props.domain}" exists: ${reportExists.value}`);
      } catch (error) {
        console.error(`[DomainBlogPost] Error checking if report exists:`, error);
        reportExists.value = false;
      }
    };
    
    // Process domain data once it's loaded
    const processDomainData = (data) => {
      console.log(`[DomainBlogPost] Processing data for domain "${props.domain}"`, data ? "Data found" : "No data");
      
      if (data) {
        domainData.value = data;
        
        // Validate minimum_offer field if present
        if ('minimum_offer' in data) {
          // Handle various types of invalid values
          if (data.minimum_offer === '' || 
              data.minimum_offer === null || 
              data.minimum_offer === undefined || 
              (typeof data.minimum_offer === 'string' && data.minimum_offer.trim() === '') ||
              isNaN(parseFloat(data.minimum_offer))) {
            // Set to null for invalid values
            domainData.value.minimum_offer = null;
          } else {
            // Convert to proper number
            domainData.value.minimum_offer = parseFloat(data.minimum_offer);
          }
        }
        
        // Generate dynamic content based on the domain
        generateUseCases();
        generateFeatures();
        
        // Process domain features if they exist as a string
        if (data.features && typeof data.features === 'string') {
          domainData.value.features = data.features.split(',').map(feature => feature.trim());
          console.log(`[DomainBlogPost] Processed ${domainData.value.features.length} features for "${props.domain}"`);
        }
        
        // Process keywords if they exist as a string
        if (data.keywords && typeof data.keywords === 'string') {
          domainData.value.keywords = data.keywords.split(',').map(keyword => keyword.trim());
          console.log(`[DomainBlogPost] Processed ${domainData.value.keywords.length} keywords for "${props.domain}": ${domainData.value.keywords.join(', ')}`);
        } else if (data.keywords && Array.isArray(data.keywords)) {
          console.log(`[DomainBlogPost] Using array keywords for "${props.domain}": ${data.keywords.join(', ')}`);
        }
        
        // Set up actions object if needed
        if (!domainData.value.actions) {
          domainData.value.actions = {};
          
          // Map escrow URLs if they exist
          if (data.escrow_buy_now_url) {
            domainData.value.actions.buy_now = data.escrow_buy_now_url;
          } else if (data.buy_now_url) {
            domainData.value.actions.buy_now = data.buy_now_url;
          }
          
          if (data.escrow_make_offer_url) {
            domainData.value.actions.make_offer = data.escrow_make_offer_url;
          }
          
          if (data.contact_email || data.emailLink) {
            domainData.value.actions.contact = data.contact_email || data.emailLink;
          }
        }
        
        // Comment out the call to updateMetaTags
        // updateMetaTags();
        console.log("Meta tag handling delegated to router.");
      } else {
        console.log(`[DomainBlogPost] No data found for domain "${props.domain}"`);
      }
      
      isLoading.value = false;
    };

    // Handle completion of loading with minimum duration
    const completeLoading = (data) => {
      const now = Date.now();
      const loadingDuration = now - loadStartTime.value;
      const minimumDuration = 750;

      if (loadingDuration >= minimumDuration) {
        processDomainData(data);
      } else {
        const remainingTime = minimumDuration - loadingDuration;
        setTimeout(() => {
          processDomainData(data);
        }, remainingTime);
      }
    };

    // Client-side fetch fallback for development or if SSG data isn't available
    const loadDomainData = async () => {
      loadStartTime.value = Date.now();
      console.log(`[DomainBlogPost] Loading data for domain: "${props.domain}"`);
      
      // Record the domain we're fetching for to check later if it's still relevant
      const fetchingForDomain = props.domain;
      
      try {
        const response = await fetch('/test_domains.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        // Check if domain changed during fetch
        if (fetchingForDomain !== props.domain) {
          console.log(`[DomainBlogPost] Domain changed during fetch from "${fetchingForDomain}" to "${props.domain}" - aborting processing`);
          return;
        }
        
        const data = await response.json();
        const foundDomain = data.find(
          (domain) => domain.name.trim().toLowerCase() === props.domain.toLowerCase()
        );
        
        console.log(`[DomainBlogPost] Found domain data for "${props.domain}": ${foundDomain ? "Yes" : "No"}`);
        completeLoading(foundDomain);
      } catch (error) {
        console.error('[DomainBlogPost] Failed to fetch domain data:', error);
        completeLoading(null);
      }
    };

    // SSG - Server-side data fetching
    // This is executed during the build process
    onServerPrefetch(async () => {
      try {
        console.log('[DomainBlogPost] Loading domain data for SSG...');
        
        // For SSG, dynamically import the JSON from the right location
        // Note: this uses a relative path that works during build
        const domains = await fetchDomainsForSSG();
        
        if (!domains || !Array.isArray(domains)) {
          console.error('[DomainBlogPost] Invalid domains data format');
          processDomainData(null);
          return;
        }
        
        const foundDomain = domains.find(
          (domain) => domain.name.trim().toLowerCase() === props.domain.toLowerCase()
        );
        
        if (foundDomain) {
          selectRandomImage();
          processDomainData(foundDomain);
        } else {
          console.error(`[DomainBlogPost] Domain not found: ${props.domain}`);
          processDomainData(null);
        }
      } catch (error) {
        console.error('[DomainBlogPost] Failed to load domain data:', error);
        processDomainData(null);
      }
    });

    // Method to fetch domains with compatibility for SSG
    const fetchDomainsForSSG = async () => {
      try {
        // For SSG, try to import from a relative path that works during build
        // Note: During SSG/build these paths are relative to the project root
        if (import.meta.env.SSR) {
          try {
            // Try paths that might work during SSG build
            const paths = [
              './src/test_domains.json',
              '../src/test_domains.json',
              '../../src/test_domains.json',
              './test_domains.json'
            ];
            
            for (const path of paths) {
              try {
                console.log(`[DomainBlogPost] Trying to import from: ${path}`);
                const module = await import(/* @vite-ignore */ path);
                console.log(`[DomainBlogPost] Successfully imported domains from: ${path}`);
                return module.default || module;
              } catch (e) {
                console.log(`[DomainBlogPost] Failed to import from: ${path}`);
                continue;
              }
            }
            throw new Error('Failed to import domains from any path during SSG');
          } catch (importError) {
            console.error('[DomainBlogPost] Import error:', importError);
            // As a fallback during SSG, use a hardcoded array of domains
            // This should only happen if there's no way to import the JSON
            console.warn('[DomainBlogPost] Using fallback empty domains array');
            return [];
          }
        } else {
          // For client-side, fetch normally
          const response = await fetch('/test_domains.json');
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        }
      } catch (error) {
        console.error('[DomainBlogPost] Error fetching domains:', error);
        return [];
      }
    };

    // Generate use cases based on domain name
    const generateUseCases = () => {
      // Extract the domain name without the .ai extension
      const domainName = props.domain.split('.')[0];
      
      // Generate use cases based on the domain name
      potentialUseCases.value = [
        `A sophisticated ${domainName}-focused AI research platform`,
        `SaaS product offering ${domainName}-related AI capabilities to enterprises`,
        `Consumer application leveraging AI for ${domainName} analysis and insights`,
        `Educational platform teaching ${domainName} concepts with AI assistance`,
        `AI-powered ${domainName} consulting services`
      ];
    };

    // Generate standard features for the domain
    const generateFeatures = () => {
      domainFeatures.value = [
        {
          title: 'Premium Extension',
          description: 'The extension itself is immediately associated with advanced technology.'
        },
        {
          title: 'Memorability',
          description: `"${props.domain.split('.')[0]}" is intuitive, easy to remember, and directly relates to its purpose.`
        },
        {
          title: 'Brand Potential',
          description: `"${props.domain.split('.')[0]}" creates a strong foundation for critical brand development.`
        },
        {
          title: 'Market Relevance',
          description: `"${props.domain.split('.')[0]}" aligns perfectly with the rapidly growing market.`
        }
      ];
    };

    // Update meta tags for SEO using existing domain metadata when available
    // Commented out to let router handle metadata
    /*
    const updateMetaTags = () => {
      // ...existing code...
    };
    */

    // Watch for route changes
    watch(() => route.path, (newPath, oldPath) => {
      console.log(`[DomainBlogPost] Route changed from "${oldPath}" to "${newPath}"`);
    });
    
    // Watch for domain prop changes
    watch(() => props.domain, (newDomain, oldDomain) => {
      console.log(`[DomainBlogPost] Domain prop changed from "${oldDomain}" to "${newDomain}"`);
      if (newDomain !== oldDomain) {
        selectRandomImage();
        loadDomainData();
        checkReportExists();
      }
    });

    // Client-side initialization
    onMounted(() => {
      console.log(`[DomainBlogPost] Component mounted for domain: "${props.domain}"`);
      selectRandomImage();
      
      // Check if a report exists for this domain
      checkReportExists();
      
      // If not already loaded by SSG, fetch data
      if (!domainData.value) {
        loadDomainData();
      }
    });

    return { 
      isLoading, 
      domainData, 
      selectedImage,
      domainFeatures, 
      potentialUseCases,
      reportExists  // Expose the new state to the template
    };
  }
});

<!-- Style tag removed, using global styles instead -->