<template>
  <div class="page-container">
    <div class="content">
      <h1 class="title-primary">Contact Us</h1>
      <p class="text-content">
        To inquire about purchasing, leasing, or developing a domain, please 
        <a 
          href="mailto:premiumAIdomains@gmail.com?subject=Interested%20in%20a%20Domain%20Name" 
          class="text-link"
        >
          contact us
        </a>.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue'; // Import the Footer component

export default {
  name: "Involved",
  components: {
    Footer, // Register the Footer component
  },
};
</script>
<!-- No styles - using global CSS instead -->