<template>
  <BlogLayout>
    <div class="domain-list">
      <section class="intro-header">
        <h1></h1>
        <p class="intro-text">
          Explore our collection of expert reports about premium domain names.
          Each report highlights the unique value and potential applications of these unique digital assets.
        </p>
      </section>

      <section class="search-section">
        <h1>Featured Reports</h1>
        
        <div v-if="isLoading">
          <LoadingMessages />
        </div>
        
        <div v-else-if="debugInfo" class="debug-info">
          <h3>Debug Information:</h3>
          <pre>{{ debugInfo }}</pre>
        </div>
        
        <div v-else-if="blogPosts.length === 0" class="no-posts">
          <p>No blog posts available. Check back soon!</p>
        </div>
        
        <div v-else class="blog-posts-container">
          <div v-for="post in blogPosts" :key="post.name" class="blog-post-item">
            <router-link :to="`/blog/${post.name}`" class="post-title-link">
              <h2 class="post-title">{{ post.name }}</h2>
            </router-link>
            <p class="post-excerpt">{{ generateExcerpt(post) }}</p>
            <div class="post-meta">
              <div class="post-tags">
                <span v-if="post.keywords && post.keywords.length" class="post-tag" v-for="(keyword, i) in getTopKeywords(post)" :key="i">
                  {{ keyword }}
                </span>
                <span v-else class="post-tag">AI Domain</span>
              </div>
              <router-link :to="`/blog/${post.name}`" class="read-more">
                Read More →
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <section class="search-section">
        <h2>Looking for the perfect domain?</h2>
        <p class="intro-text">Browse our complete collection of premium domain names available for purchase.</p>
        <div class="button-container">
          <a href="/" class="buy-now-button">
            View All Domains
          </a>
        </div>
      </section>
    </div>
  </BlogLayout>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import BlogLayout from './BlogLayout.vue';
import LoadingMessages from "@/components/LoadingMessages.vue";
import { useHead } from '@unhead/vue';

export default defineComponent({
  name: 'BlogIndex',
  components: {
    BlogLayout,
    LoadingMessages
  },
  setup() {
    const isLoading = ref(true);
    const blogPosts = ref([]);
    const debugInfo = ref(null);
    
    // SEO metadata
    useHead({
      title: 'AI Domain Insights | baj.ai Blog',
      meta: [
        { name: 'description', content: 'Explore insights about premium AI domain names and their potential applications for innovative projects.' },
        { property: 'og:title', content: 'AI Domain Insights | baj.ai Blog' },
        { property: 'og:description', content: 'Explore insights about premium AI domain names and their potential applications for innovative projects.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://baj.ai/blog' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'AI Domain Insights | baj.ai Blog' },
        { name: 'twitter:description', content: 'Explore insights about premium AI domain names and their potential applications for innovative projects.' }
      ]
    });

    // Helper to generate a meaningful excerpt from domain data
    const generateExcerpt = (domain) => {
      if (domain.description && domain.description.length > 10) {
        return domain.description.substring(0, 150) + (domain.description.length > 150 ? '...' : '');
      } else {
        const domainRoot = domain.name.split('.')[0];
        return `Explore the potential of ${domain.name} for innovative AI applications in the ${domainRoot} space.`;
      }
    };

    // Get top 3 keywords from domain data for tags
    const getTopKeywords = (domain) => {
      if (!domain.keywords) return [];
      
      let keywords = domain.keywords;
      if (typeof keywords === 'string') {
        keywords = keywords.split(',').map(k => k.trim());
      }
      
      return keywords.slice(0, 3);
    };

    // Try to find the domains json file using various potential paths
    const tryPaths = [
      '/test_domains.json',
      '/src/test_domains.json',
      './test_domains.json',
      '../src/test_domains.json',
      'src/test_domains.json',
      '../test_domains.json',
      '../../test_domains.json'
    ];

    // Fetch domains data, trying multiple paths
    const fetchDomains = async () => {
      console.log("🔍 Attempting to locate and load test_domains.json...");
      let lastError = null;
      
      // Track fetch attempts
      let fetchAttempts = [];
      
      // Try each path in sequence
      for (const path of tryPaths) {
        try {
          console.log(`Trying path: ${path}`);
          fetchAttempts.push(`Trying: ${path}`);
          
          const response = await fetch(path);
          
          if (!response.ok) {
            const statusText = `Status: ${response.status} ${response.statusText}`;
            console.log(`Path ${path} - ${statusText}`);
            fetchAttempts.push(`  ${statusText}`);
            continue;
          }
          
          const data = await response.json();
          console.log(`✅ Successfully loaded from ${path} - Found ${data.length} domains`);
          
          // Enhanced debugging: Log all domains to identify unexpected entries
          console.log("Domain names in the loaded file:", data.map(d => d.name).join(", "));
          
          // Check for specific phantom domains
          const phantomDomains = ["intellect.ai", "neural.ai", "cognitive.ai", "quantum.ai", "creative.ai"];
          const foundPhantoms = data.filter(d => phantomDomains.includes(d.name));
          
          if (foundPhantoms.length > 0) {
            console.warn("⚠️ Found phantom domains:", foundPhantoms.map(d => d.name).join(", "));
            console.warn("Full data of first phantom domain:", JSON.stringify(foundPhantoms[0], null, 2));
          }
          
          fetchAttempts.push(`  Success! Found ${data.length} domains`);
          fetchAttempts.push(`  Domains: ${data.slice(0, 10).map(d => d.name).join(", ")}${data.length > 10 ? '...' : ''}`);
          
          // Sort alphabetically
          blogPosts.value = data.sort((a, b) => a.name.localeCompare(b.name));
          isLoading.value = false;
          return;
        } catch (error) {
          console.error(`Failed with path ${path}:`, error);
          fetchAttempts.push(`  Error: ${error.message}`);
          lastError = error;
        }
      }
      
      // If we get here, all attempts failed
      console.error('All attempts to load test_domains.json failed');
      debugInfo.value = `Failed to load domains data.\n\nAttempts:\n${fetchAttempts.join('\n')}\n\nLast Error: ${lastError?.message || 'Unknown error'}`;
      blogPosts.value = [];
      isLoading.value = false;
    };
    
    onMounted(() => {
      console.log("🎉 BlogIndex component mounted");
      // Use a slight delay to ensure DOM is fully available
      setTimeout(() => {
        fetchDomains();
      }, 100);
    });
    
    return {
      isLoading,
      blogPosts,
      debugInfo,
      generateExcerpt,
      getTopKeywords
    };
  }
});
</script>

<!-- Style tag removed, using global styles instead -->