<template>
  <div class="app">
    <header class="header">
      <!-- Search bar commented out -->
    </header>
    <router-view />
  </div>
</template>

<script>
import './assets/styles/global.css';
import Involved from './components/Involved.vue';

export default {
  components: {
    Involved,
  },
  data() {
    console.log("App.vue - data() initialized");
    return {
      searchQuery: "",
      lastProcessedQuery: null,
    };
  },
  methods: {
    search() {
      console.log("App.vue - search method triggered");
      console.log("Current searchQuery before trimming:", this.searchQuery);

      const trimmedQuery = this.searchQuery.trim();
      console.log("Trimmed searchQuery:", trimmedQuery);

      if (trimmedQuery === this.lastProcessedQuery) {
        console.log("App.vue - Search query unchanged; skipping router push.");
        return;
      }

      this.$router.push({
        name: "Home",
        query: { q: trimmedQuery || undefined },
      });
      console.log("Router updated with query:", trimmedQuery);

      if (!trimmedQuery) {
        console.warn("App.vue - Empty searchQuery submitted.");
      }

      this.lastProcessedQuery = trimmedQuery;
      console.log("App.vue - lastProcessedQuery updated:", this.lastProcessedQuery);
    },
    logState() {
      console.log("=== App.vue Current State ===");
      console.log("searchQuery:", this.searchQuery);
      console.log("Route query.q:", this.$route.query.q);
      console.log("lastProcessedQuery:", this.lastProcessedQuery);
      console.log("=============================");
    },
    // Helper methods for updating meta tags
    updateMetaTag(name, content) {
      if (!content) return;
      let tag = document.querySelector(`meta[name='${name}']`);
      if (!tag) {
        tag = document.createElement("meta");
        tag.setAttribute("name", name);
        document.head.appendChild(tag);
      }
      tag.setAttribute("content", content);
      console.log(`Meta tag "${name}" updated:`, content);
    },
    
    updateOGMetaTag(property, content) {
      if (!content) return;
      let tag = document.querySelector(`meta[property='${property}']`);
      if (!tag) {
        tag = document.createElement("meta");
        tag.setAttribute("property", property);
        document.head.appendChild(tag);
      }
      tag.setAttribute("content", content);
      console.log(`OG meta tag "${property}" updated:`, content);
    },
    
    updateLinkTag(rel, href) {
      if (!href) return;
      // Remove existing tags of this relation
      const existingTags = document.querySelectorAll(`link[rel='${rel}']`);
      existingTags.forEach(tag => tag.remove());
      
      // Add the new tag
      const tag = document.createElement("link");
      tag.setAttribute("rel", rel);
      tag.setAttribute("href", href);
      document.head.appendChild(tag);
      console.log(`Link tag "${rel}" updated:`, href);
    },
    
    updateStructuredData(contentType, meta, path) {
      // Remove any existing JSON-LD script
      const existingScripts = document.querySelectorAll("script[type='application/ld+json']");
      existingScripts.forEach(script => script.remove());
      
      // Set up base schema
      let schema = {
        "@context": "https://schema.org"
      };
      
      // Use the structured data from meta if available
      if (meta.structuredData) {
        schema = { ...schema, ...meta.structuredData };
      } else {
        // Create appropriate schema based on content type
        if (contentType === 'product') {
          schema = {
            ...schema,
            "@type": "Product",
            "name": meta.title?.replace(" | Baj.ai", "") || "Premium Domain",
            "description": meta.description || "",
            "offers": {
              "@type": "Offer",
              "availability": "https://schema.org/InStock",
              "priceCurrency": "USD",
              "url": `${window.location.origin}${path}`
            }
          };
        } else if (contentType === 'article' || contentType === 'blog') {
          schema = {
            ...schema,
            "@type": "Article",
            "headline": meta.title?.replace(" | Baj.ai Blog", "") || "Domain Analysis",
            "description": meta.description || "",
            "author": {
              "@type": "Organization",
              "name": "Baj.ai"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Baj.ai",
              "logo": {
                "@type": "ImageObject",
                "url": `${window.location.origin}/favicon.ico`
              }
            },
            "datePublished": new Date().toISOString().split('T')[0],
            "dateModified": new Date().toISOString().split('T')[0],
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `${window.location.origin}${path}`
            }
          };
        } else {
          // Default to WebPage for other content types
          schema = {
            ...schema,
            "@type": "WebPage",
            "name": meta.title || "Baj.ai",
            "description": meta.description || "",
            "url": `${window.location.origin}${path}`
          };
        }
      }
      
      // Add the schema to the page
      const script = document.createElement("script");
      script.setAttribute("type", "application/ld+json");
      script.textContent = JSON.stringify(schema);
      document.head.appendChild(script);
      console.log(`Structured data updated for content type: ${contentType}`);
    },
    
    updateMetaTags() {
      // Skip during SSR
      if (import.meta.env.SSR) return;
      
      // Get metadata from route
      const meta = this.$route.meta || {};
      const path = this.$route.path;
      const isBlogPost = meta.isBlogPost || false;
      const contentType = meta.contentType || 'website';
      
      // Set page title
      document.title = meta.title || 'Baj.ai - Premium Domains';
      console.log("Title updated:", meta.title);
      
      // Update meta description
      this.updateMetaTag('description', meta.description);
      
      // Update robots tag - allow indexing by default
      this.updateMetaTag('robots', meta.robots || 'index, follow');
      
      // Update canonical URL - use function if provided
      let canonicalUrl = typeof meta.canonical === 'function' 
        ? meta.canonical(this.$route) 
        : meta.canonical || `${window.location.origin}${path}`;
      
      this.updateLinkTag('canonical', canonicalUrl);
      
      // Set different structured data based on content type
      this.updateStructuredData(contentType, meta, path);
      
      // Update Open Graph tags
      const ogTitle = isBlogPost 
        ? `${meta.title} | Baj.ai Analysis` 
        : `${meta.title} | Baj.ai Domains`;
      
      this.updateOGMetaTag('og:title', ogTitle);
      this.updateOGMetaTag('og:description', meta.description);
      this.updateOGMetaTag('og:url', canonicalUrl);
      this.updateOGMetaTag('og:type', isBlogPost ? 'article' : 'website');
      
      // Add image if available
      if (meta.ogImage) {
        this.updateOGMetaTag('og:image', meta.ogImage);
      }
      
      // Update Twitter Card tags
      this.updateMetaTag('twitter:card', 'summary_large_image');
      this.updateMetaTag('twitter:title', ogTitle);
      this.updateMetaTag('twitter:description', meta.description);
      
      // Add image if available
      if (meta.ogImage) {
        this.updateMetaTag('twitter:image', meta.ogImage);
      }
      
      console.log("Meta tags updated successfully");
    }
  },
  created() {
    console.log("App.vue - created lifecycle hook triggered");

    this.searchQuery = this.$route.query.q || "";
    this.lastProcessedQuery = this.searchQuery;
    console.log("Initial query loaded in App.vue:", this.searchQuery);

    // Only update meta tags on client-side
    if (!import.meta.env.SSR) {
      this.updateMetaTags();
    }

    this.logState();
  },
  watch: {
    "$route.query.q": {
      handler(newQuery) {
        console.log("App.vue - Watcher triggered for $route.query.q");
        console.log("New query value:", newQuery);

        if (newQuery === this.lastProcessedQuery) {
          console.log("App.vue - Watcher detected no change in query. Skipping update.");
          return;
        }

        this.searchQuery = newQuery || "";
        console.log("searchQuery updated from watcher:", this.searchQuery);

        this.logState();

        this.lastProcessedQuery = this.searchQuery;
        console.log("App.vue - lastProcessedQuery updated by watcher:", this.lastProcessedQuery);
      },
      immediate: true,
    },
    "$route.fullPath": {
      handler() {
        console.log("Route changed. Updating meta tags.");
        // Only update meta tags on client-side
        if (!import.meta.env.SSR) {
          this.updateMetaTags();
        }
      },
      immediate: true,
    },
  },
};
</script>